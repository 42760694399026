<template>
  <Head>
    <title>Termoland {{ $titleEnd }}</title>
    <meta name="description" content="Termoland для семьи и отдыха" />
    <meta property="og:title" :content="'Termoland ' + $titleEnd" />
    <meta property="og:description" content="Termoland для семьи и отдыха" />
    <meta property="og:image" content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/GcaaogIqfHh8nj9abjg0ItrUnGJziLHHyMvopSpY.jpg" />
  </Head>
  <main class="main my-0 termoland">
    <Hero :content="hero" :class="'termoland__hero'" />
    <Basis :content="basis" :class="'termoland__content'" />
    <Image :content="image" :class="'termoland__spa'" />
    <Gallery :content="gallery" :class="'termoland__gallery'" />
    <Green :content="green" :class="'termoland__green'" />
    <Others />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import Gallery from "@/components/adv/inner/Gallery.vue";
import Green from "@/components/adv/inner/Green.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
import videoDesk from '@i/termoland/video/desk.mp4';
import videoMob from '@i/termoland/video/mob.mp4';
import videoPoster from '@i/termoland/video/poster.png';

export default {
  name: "Sport",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    Gallery,
    Green,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "Termoland <br>для семьи и отдыха",
        text: "<p>Семейный SPA-комплекс Termoland - оазис спокойствия и уюта рядом с домом.</p>",
        background: require("@i/termoland/banner.png"),
        more: "Узнайте больше",
      },
      basis: {
        title: 'Отдыхайте <br class="termoland__br">и наполняйтесь энергией <br><span class="text-masked">в Termoland Ботаническая</span>',
        subtitle: "2 500 кв. м SPA&nbsp;на территории",
        text: `<p>Откройте для себя невероятное сочетание фитнеса, комфорта, красоты природы и высокого уровня сервиса.</p>`,
        video: {
          poster: videoPoster,
          sourceDesk: videoDesk,
          sourceMob: videoMob,
          volume: true,
        }
      },
      image: {
        title: "Ваш собственный<br>SPA-комплекс",
        text: "<p>Городской акватермальный курорт станет вашем любимым местом восполнения энергии, оздоровления, релакса и хорошего времяпровождения. Здесь время замедляется, давая вам уникальную возможность насладиться каждой прожитой минутой.</p>",
        img: require("@i/termoland/spa.png"),
      },
      gallery: [
        {
          title: '<br class="d-none d-md-block">Бассейны <br>в том числе, уличный',
          img: require("@i/termoland/gallery/1.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">Бани',
          img: require("@i/termoland/gallery/2.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">Сауны',
          img: require("@i/termoland/gallery/3.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">Хаммам',
          img: require("@i/termoland/gallery/4.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">Коллективные парения',
          img: require("@i/termoland/gallery/5.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">SPA-зона',
          img: require("@i/termoland/gallery/6.jpg"),
        },
        {
          title: '<br class="d-none d-md-block">Кафе',
          img: require("@i/termoland/gallery/7.jpg"),
        },
      ],
      green: {
        title: "Termoland <br>Ботаническая",
        img: require("@i/termoland/preview.png"),
      },
    };
  },
};
</script>

<style>
.termoland {
  background: #f8f8f8;
}

.termoland__content .text {
  margin-bottom: 46px;
}

.termoland__green .image__wrap {
  margin-bottom: 0;
}

.termoland__gallery .item__content {
  min-height: 70px;
}

.termoland__content .content {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .termoland .content {
    flex-direction: column-reverse;
  }

  .termoland__hero .content {
    justify-content: center;
  }

}

@media (max-width: 768px) {
  .termoland__hero .title {
    margin-top: 0;
  }

  .termoland__gallery .item__title {
    margin-top: 32px;
  }

  .termoland__content .subtitle {
    margin-bottom: 16px;
  }

  .termoland__green .title {
    margin-bottom: 0;
    margin-top: 40px;
  }

  .termoland__spa {
    padding-bottom: 0 !important;
  }

  .termoland__spa .item {
    margin-bottom: 0;
  }

  .termoland__gallery .item__content {
    min-height: unset;
  }

  .termoland__gallery {
    padding-top: 0 !important;
  }

  .termoland__br {
    display: none;
  }

  .termoland .content .text {
    margin-bottom: 24px;
  }

  .termoland .home-block {
    padding: 56px 0;
  }
}
</style>
